<!--运营报修确认-->
<template>
  <div>
    <component :is="componentName"></component>
  </div>
</template>

<script>
import orderStatisticDetail from './detail'
import orderStatisticList from "./list";
export default {
  name: "work-order-statistics",
  components:{
    orderStatisticDetail,
    orderStatisticList
  },
  watch:{
    '$route.path':function (newval) {
      if(newval.includes('/detail')){
        this.componentName = 'orderStatisticDetail'
        return;
      }
      if(newval.includes('/propertyModule/data-report/workorder-statistics')){
        this.componentName = 'orderStatisticList'
      }
    }
  },
  data(){
    return {
      componentName:'orderStatisticList'
    }
  }
}
</script>

<style scoped>

</style>