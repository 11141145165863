
<template>
  <div>
    <property-order-detail :id="id"></property-order-detail>
    <div class="occupy-ele"></div>
    <div class="fixed-bottom-btn">
      <el-row>
        <el-button class="btn-default" size="medium" @click="back">返回</el-button>
      </el-row>
    </div>
  </div>
</template>

<script>
import PropertyOrderDetail from "../../components/property-order-detail";
export default {
  name: "order-statistic-detail",
  components: {PropertyOrderDetail},
  data(){
    return {
      id:this.$route.params.id
    }
  },
  methods:{
    back(){
      this.$router.replace('/propertyModule/data-report/workorder-statistics')
    }
  }
}
</script>

<style scoped lang="scss">

</style>