
<!-- 工单统计 -->
<template>
  <div class="order-wrapper flex">
    <div class="search-content searchBox">
          <div class="search-item">
            <label>工单号</label>
            <el-input type="text" v-model="filter.orderNo" placeholder="请输入" size="medium" clearable></el-input>
          </div>
          <div class="search-item">
            <label>状态</label>
            <el-select v-model="filter.status" size="medium" placeholder="请选择" clearable>
              <el-option v-for="(st,index) in statusMap.keys()" :label="statusMap.get(st)[1]" :key="index" :value="st"></el-option>
            </el-select>
          </div>
          <div class="search-item">
            <label>完成时间</label>
            <el-date-picker class="range-input"
                            :default-time="['00:00:00','23:59:59']"
                            size="medium"
                            :picker-options="pickerOptions"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            type="datetimerange"
                            is-range
                            v-model="time"
                            range-seperator="至"
                            start-placeholder="开始时间"
                            @change="timeChange"
                            end-placeholder="结束时间"></el-date-picker>
          </div>
          <div class="search-item button-group  pdl16">
            <el-button type="primary" class="btn-search" @click="search()" size="medium">查询</el-button>
            <el-button type="primary" class="btn-search" @click="exportTable()" size="medium">导出</el-button>
            <el-button  class="btn-reset" @click="time=[];resetSearch()" size="medium">重置</el-button>
          </div>
    </div>
    <div class="table-content inline-flex contentBox">
      <el-table id="order_table" :data="tableData" class="contentTable" size="medium" row-key="id" :tree-props="{children:'child',hasChildren:'hasChildren'}">
        <el-table-column prop="orderNo" label="工单号"></el-table-column>
        <el-table-column prop="areaName" label="报修区域">
          <template slot-scope="{row}">
            {{row.repairArea.areaName}}
          </template>
        </el-table-column>
        <el-table-column label="报修人员">
          <template slot-scope="{row}">
            {{row.createBySysUserName.username}}
          </template>
        </el-table-column>
        <el-table-column prop="repairUserName" label="维修人员"></el-table-column>
        <el-table-column prop="typeName" label="报修类型">
          <template slot-scope="{row}">
            {{row.repairType.typeName}}
          </template>
        </el-table-column>
        <el-table-column  width="150" prop="createTime" label="报修时间"></el-table-column>
        <el-table-column label="维修费用">
          <template slot-scope="{row}">
            {{row.status!=7&&row.isPaid?(row.price?row.price/100:''):'-'}}
          </template>
        </el-table-column>
        <el-table-column prop="statusName" label="状态">
          <template slot-scope="{row}">
             <span class="property-status" :class="[getStatus(row.status,'className')]" >
              {{row.statusName}}</span>
          </template>
        </el-table-column>
        <el-table-column  width="150" prop="finishTime" label="工单完成时间"></el-table-column>
        <el-table-column label="评价">
          <template slot-scope="{row}">
            {{row.status!=7?(row.scoreAttitude+row.scoreSolveQuality+row.scoreSolveSpeed):'-'}}
          </template>
        </el-table-column>
        <el-table-column  width="150" prop="elapsedTime" label="工单耗时"></el-table-column>
        <el-table-column  label="操作">
          <template  slot-scope="{row}">
            <span class="mgr10 blue" @click="showDetail(row.id)">查看</span>
          </template>
        </el-table-column>
      </el-table>
      <reconova-pagination @paginationChange="paginationChange" :page-no="pageIndex" :page-size="pageSize" :total="total"></reconova-pagination>
    </div>
    <div class="dialog-wrapper">
    </div>
  </div>
</template>

<script>
import ReconovaPagination from "@/components/reconova/reconovaPagination";
import pagination from "@/mixins/pagination";
import {exportTable, onDownload} from "../../../../utils/utils";
export default {
  name: "order-statistic-list",
  components: {
    ReconovaPagination
  },
  mixins:[pagination],
  data(){
    return {
      filter:{
        orderNo:'',
        status:'',
        finishStartTime:'',
        finishEndTime:''
      },
      time:[],
      editData:{},
      tableData:[],
      statusMap : new Map([
        [7,["terminated",'已终止']],
        [13,['done','已完成']],
      ]),
    }
  },
  mounted(){
    this.search()
  },
  methods:{
    // 查询
    search(pageSize=10,pageIndex=1){
      let requestParam = this.getParams(pageSize,pageIndex);
      this.$api.property.orderStatistic(requestParam).then(response=>{
        this.tableData = response.data.list;
        this.total = response.data.total;
        this.pageIndex = response.data.pageNum;
        this.pageSize = response.data.pageSize;
      })
    },

    /**
     * @param status  -- 状态
     * @param prop  -- className/statusName
     */
    getStatus(status,prop){
      return prop==='className'?this.statusMap.get(status)[0]:this.statusMap.get(status)[1]
    },

    showDetail(id){
      this.$router.push(`/propertyModule/data-report/workorder-statistics/detail/${id}`)
    },

    timeChange(value){
      this.filter.finishStartTime = value?value[0]:'';
      this.filter.finishEndTime = value?value[1]:'';
    },

    exportTable(){
      let param = this.getParams()
      this.$api.property.exportOrderStatistic(param).then(res=>{
        let { headers, data } = res;
        onDownload(headers, data);
      })
    }
  }
}
</script>

<style scoped lang="scss">
.property-status.to-be-confirm{
  background: #188ffe;
}
.property-status.to-be-assigned{
  background:#C280FE;
}
.property-status.to-be-review{
  background: #5D68FD;
}
.property-status.to-be-pay{
  background: #FA5151;
}
.property-status.to-be-repair{
  background: $handlingColor;
}
.property-status.in-maintain{
  background:#FF8D19;
}
.property-status.to-be-check{
  background:#095AD9;
}
.property-status.to-be-evaluate{
  background: #FAAD14;
}
.property-status.rejected{
  background: #FF6490;
}
.property-status.terminated{
  background: #DA001B;
}
.property-status.to-be-accept{
  background: $evaluateColor;
}
.property-status.done{
  background: $completeColor;
}
.el-input,.el-select {
  width: 220px;
}
.range-input{
  width: 400px;
}
.el-table{
  flex: 1;
  overflow-y: auto;
}
</style>